


































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
const columns = [
    {
        title: '角色名称',
        key: 'name',
    },
    {
        title: '操作',
        slot: 'action',
        // width: 400
    },
]
export default Vue.extend({
    data() {
        return {
            loading: false,
            pageNum: 1,
            pageSize: 10,
            columns,
            data: [],
            formData: {},
        }
    },
    methods: {
        getData() {
            this.loading = true
            request.get('/api/respond/respond_role/list', null)
            .then((data) => {
                this.data = data
                this.loading = false
            })
        },
        deleteItem(id: string) {
            request.del(`/api/respond/respond_role/${id}`)
            .then((res) => {
                this.$Message.success('删除成功!')
                this.getData()
            }).catch((errorMsg) => {
                this.$Message.error(errorMsg)
            })
        },
        /* 改变pageNum */
        changPageNum(num: number) {
            this.pageNum = num
            this.getData()
        },
    },
    mounted() {
        this.getData()
    },
})
