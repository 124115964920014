import { render, staticRenderFns } from "./role_list.vue?vue&type=template&id=48e45fb7&scoped=true&"
import script from "./role_list.vue?vue&type=script&lang=ts&"
export * from "./role_list.vue?vue&type=script&lang=ts&"
import style0 from "./role_list.vue?vue&type=style&index=0&id=48e45fb7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48e45fb7",
  null
  
)

export default component.exports